ngApp.controller('ReportController', ['$scope', '$http', '$attrs', '$filter', '$storage', '$log', function ($scope, $http, $attrs, $filter, $storage, $log) {
  var that = this;
  var all_rows = [];
  $scope.load_now = (typeof $attrs.autoload != 'undefined') ? $attrs.autoload : '1';

  $scope.loaded = false;
  $scope.order_col = '';
  $scope.reversal = false;
  $scope.cols = [];
  $scope.rows = [];
  $scope.filters = {};

  $scope.colFormats = (typeof $attrs.colFormats != 'undefined') ? angular.fromJson($attrs.colFormats) : {};
  $scope.summaryCols = (typeof $attrs.summaryCols != 'undefined') ? angular.fromJson($attrs.summaryCols) : {};

  $scope.currentPage = (typeof $attrs.currentPage != 'undefined') ? $attrs.currentPage : 1;
  $scope.itemsPerPage = (typeof $attrs.itemsPerPage != 'undefined') ? $attrs.itemsPerPage : 25;
  $scope.totalItems = $scope.rows.length;

  $scope.$on('storage:ready', function () {
    //$log.log('items per page can load')
    if($storage.has('itemsPerPage')){
      $scope.itemsPerPage = $storage.get('itemsPerPage');
    }
  });

  $scope.format = function (val, col_name) {
    if(typeof $scope.colFormats[col_name] == 'undefined' || $scope.cols.indexOf(col_name) == - 1){
      return val;
    }

    val = val * 1;
    switch($scope.colFormats[col_name]){
      case 'number':
        return val.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').split('.')[0];
        break;

      case 'dollars':
        return '$' + val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        break;

      case 'precision':
        return val.toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        break;

      case 'percentage':
        return '$' + val.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%';
        break;

      case 'microtransaction':
        return '$' + val.toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        break;

      case 'date':
        return val.replace('00:00:00','')
        break;

      default:
        return val;
        break;
    }

  }

  $scope.showSummaryRow = function () {
    return (Object.keys($scope.summaryCols).length !== 0);
  }

  $scope.summarize = function (col_name) {
    //$log.log($scope.cols);
    if(typeof $scope.summaryCols[col_name] == 'undefined' || $scope.cols.indexOf(col_name) == - 1){
      return '';
    }

    switch(true){

      case ($scope.summaryCols[col_name] == 'sum'):
        var sum = 0;
        for(var i = 0; i < $scope.rows.length; i ++){
          sum += $scope.rows[i][col_name] * 1
        }
        return $scope.format(sum, col_name);
        break;

      case ($scope.summaryCols[col_name] == 'avg'):
        var sum = 0;
        for(var i = 0; i < $scope.rows.length; i ++){
          sum += $scope.rows[i][col_name] * 1
        }
        return '' + $scope.format(Math.round((sum / ($scope.rows.length)) * 100) / 100, col_name);
        break;

      case ($scope.summaryCols[col_name] == 'date'):

        var dates = [];
        for(var i = 0; i < $scope.rows.length; i ++){
          dates.push(new Date($scope.rows[i][col_name]))
        }
        var min = dates.reduce(function (a, b) {
          return a < b ? a : b;
        });
        var max = dates.reduce(function (a, b) {
          return a > b ? a : b;
        });

        return min.toISOString().substring(0, 10) + ' to ' + max.toISOString().substring(0, 10);
        break;

      default:
        return 'summary not defined';
        break;
    }
  }

  $scope.deleteRow = function (row) {
    var row_was, i_was;

    for(var i in all_rows){
      if(row.id == all_rows[i].id){
        row_was = all_rows[i];
        i_was = i;
        delete all_rows[i];
        break;
      }
    }

    that.makeRows();

    $http.delete($attrs.deleteRoute.replace('_ID_', row.id))
      .then(
        function (response) {
          bootStrapAlert('Successfuly deleted!');
        }
      )
      .catch(
        function (response) {
          bootStrapAlert('Failed to delete!', 'error');
          all_rows[i_was] = row_was;
          that.makeRows();
        }.bind(row_was, i_was, that)
      );
  };

  $scope.setOrderCol = function (col) {

    if($scope.order_col == col){
      $scope.reversal = ! $scope.reversal;
    }else{
      $scope.reversal = false;
    }
    $scope.order_col = col;
  };
  
  this.makeRows = function () {
    $scope.rows = $filter('filter')(all_rows, $scope.filters);
  };

  $scope.route = function (actionRoute, row) {
    var matches = actionRoute.match(/_[A-Z\-]+_/);

    if(! ! matches){
      for(var i = 0; i < matches.length; i ++){
        var targ = matches[i];
        var k = matches[i].split('_').join('').toLowerCase().replace('-', '_');

        if(typeof row[k] != 'undefined'){
          actionRoute = actionRoute.replace(targ, row[k]);
        }
      }
    }

    return actionRoute;

  };

  $scope.$watch('itemsPerPage', function () {
    //$log.log('items per page changed')
    $storage.put('itemsPerPage', $scope.itemsPerPage);
  });

  $scope.$watch('rows', function () {
    $scope.totalItems = $scope.rows.length;
  });
  $scope.$watch('load_now', function () {
    if($scope.load_now == '1'){
      $scope.loadData();
    }
  });
  $scope.$watchCollection('filters', function () {
    that.makeRows();
  });

  $scope.setPage = function (pageNo) {
    $scope.currentPage = pageNo;
  };

  $scope.pageChanged = function () {
    $scope.$emit('pageChanged', [$scope.currentPage]);
  };

  $scope.pageCount = function () {
    return Math.ceil($scope.totalItems / $scope.itemsPerPage);
  };

  $scope.displayAs = function (row, key) {

    switch(true){
      case (typeof row[key] != 'undefined' && typeof this.colFormats[key] != 'undefined' && this.colFormats[key] == 'date'):
        return this.colFormats[key];
        break;

      case (typeof row[key] != 'undefined' && typeof this.colFormats[key] != 'undefined' && this.colFormats[key] == 'boolean'):
        return 'boolean';
        break;

      case (typeof row[key] != 'undefined' && row[key] != null && row[key].length > 97):
        return 'textarea';
        break;

      case (typeof row[key] != 'undefined' && typeof this.colFormats[key] != 'undefined'):
        return this.colFormats[key];
        break;

      default:
        return 'input-text';
        break;
    }
  };

  $scope.getCSVData = function () {
    var csv_data = [];
    var cols = $scope.cols;

    if(typeof $scope.rows[0] != "undefined" &&  typeof $scope.rows[0]['io_bond'] != "undefined"){
      cols.push('io_bond');
    }

    csv_data.push(cols);

    for(var row in $scope.rows){
      row = $scope.rows[row];
      var csv_row = {};
      for(var col in $scope.cols){
        col = $scope.cols[col];
        csv_row[col] = row[col];
      }

      if(typeof row['io_bond'] != 'undefined'){
        csv_row['io_bond'] = row['io_bond'];
      }

      csv_data.push(csv_row);
    }

    return csv_data;
  };

  $scope.updateServer = function (id, key, val) {

    $http.put($attrs.feed + '/' + id, {'key': key, 'val': val})
      .then(
        function (response) {

          var msg = 'Successfully updated!';
          if(typeof response.data != 'undefined' && typeof response.data.message != 'undefined'){
            msg = '<h1>' + msg + '</h1><p>' + response.data.message + '</p>'
          }

          bootStrapAlert(msg, 'success');
        }
      ).catch(
      function (response) {
        var msg = 'Failed to update!';
        if(typeof response.data != 'undefined' && typeof response.data.message != 'undefined'){
          msg = '<h1>' + msg + '</h1><p>' + response.data.message + '</p>'
        }

        if(typeof response.data.errors != 'undefined'){
          for(var input_name in response.data.errors){
            var input_errors = response.data.errors[input_name];
            for(var i = 0; i < input_errors.length; i ++){
              msg = msg + '<div>' + input_errors[i] + '</div>'
            }
          }
        }

        bootStrapAlert(msg, 'danger', {timeout: 2000});
      }
    );
  };

  $scope.loadData = function () {

    $http.get($attrs.feed, {cache: false})
      .then(function (response) {
        var data = response.data;

        if(data.length){
          $scope.cols = (typeof data.cols !== 'undefined') ? data.cols : Object.getOwnPropertyNames(data[0]);

          if(typeof $attrs.displayCols != 'undefined'){
            $scope.cols = $attrs.displayCols.split(',');
          }

          all_rows = (typeof data.rows !== 'undefined') ? data.rows : data;
          that.makeRows();
        }

        $scope.loaded = true;
        setTimeout(function(){
          $('*[data-resizable-columns-id="grid-'+ md5($attrs.feed) +'"]').resizableColumns({store:store});
        }, 25)


      }).catch(function (response) {
      $log.log(response);
    });

  }

}]);